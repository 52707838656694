import React, { useState } from 'react';
import './MatchDetails.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { match } from 'assert';

export default function Matches() {
  const [matches, setMatches] = useState<any[] | null>(null);

  if(matches == null) {
    fetch(`/api/match/`).then((response) => response.json()).then((matches) => matches.reverse()).then(setMatches);
  }

  return (
    <>
     <Helmet>
        <title>Matches</title>
      </Helmet>

    {matches &&
        <ul className="matches">
        {matches.map((match, index) => (
            <li key={index}> 
                <Link to={{ pathname: `/match/${match.id}`}}>{match.name}</Link>
            </li>
        ))}
        </ul>
    }
    </>
  );
}

