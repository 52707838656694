import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import MatchDetails from './routes/match/MatchDetails';
import HomePage from './routes/HomePage';
import Matches from './routes/match/list';

function App() {
  return (
    <div className="App">
      <header>
        <img className='logo' alt='logo' src="/logo192.png" /><span>&nbsp;Starium</span>
      </header>
      <Routes>
        <Route path="/">
          <Route path="/match/:id" element={<MatchDetails />} />
          <Route path="/matches" element={<Matches />} />
          <Route path="/" element={<HomePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
