import React from 'react';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import "./style.css";

import ShareIcon from "../../share-nodes-svgrepo-com.svg";

const SECONDS_PER_DAY = 86400;
const HOURS_PER_DAY = 24;

export interface MatchEventType {
    time: number;
    eventType: string;
    id: string;
}

interface MatchEventTypeListElementProps {
    event: MatchEventType;
    title?: string;
    url: string;
    onClick: (event: MatchEventType) => void;
}



/**
 * Convert seconds to HH:MM:SS
 * If seconds exceeds 24 hours, hours will be greater than 24 (30:05:10)
 *
 * @param {number} seconds
 * @returns {string}
 */
const secondsToHms = (seconds: number) => {
  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const remainderSeconds = seconds % SECONDS_PER_DAY;
  const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
  return hms.replace(/^(\d+)/, h => `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, '0'));
};


// Create the functional component using React.FC
const MatchEventTypeListElement: React.FC<MatchEventTypeListElementProps> = (props) => {

  async function shareEvent(event: any){
    event.preventDefault();
    event.stopPropagation();
    try {
      await navigator.share({
        url: `${props.url}?t=${props.event.time}`,
        text: `${props.title} \n${props.event.eventType} - ${secondsToHms(props.event.time)}\n`
      });
    }
    catch(error) {} 
  }

  return (
    <li className='event-item' onClick={() => props.onClick(props.event)}>
        <div className='box'></div>
        <div className='title'>{props.event.eventType}</div>
        <div className="time">{secondsToHms(props.event.time)}</div>
        <div className='share' onClick={shareEvent}><img alt="share" src={ShareIcon} /></div>
    </li>
     
  );
}

export default MatchEventTypeListElement;