import React from 'react';
import './HomePage.css';
import { Helmet } from 'react-helmet-async';

function HomePage() {
  return (
    <>
     <Helmet>
        <title>Starium.tv</title>
        <meta name="description" content="Starium.tv" />
        <meta property="og:title" content="Starium.tv" />
        <meta property="og:url" content={`https://starium.tv/`} />
      </Helmet>
      <div className="CommingSoon">
        
        Comming Soon
      </div>
    </>
   
  );
}

export default HomePage;
